/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-03-22",
    versionChannel: "nightly",
    buildDate: "2023-03-22T00:22:15.218Z",
    commitHash: "d7c842a1393b9824ae9f455b60843dc76a341b4d",
};
